import React from 'react'
import '../styles/contact.scss'
import Layout from "../components/layout";
import {StaticImage} from "gatsby-plugin-image";

const ContactUs = () => (
    <Layout>
        <section className='section-contact'>
            <div className='container'>
                <div className='intro'>
                    <h2>Get in touch with our teams</h2>
                    <p>Use the information below to contact our sales and support teams.</p>
                </div>
                <div className='details'>
                    <article className='group'>
                        <div className='group-heading'>
                            <span className='group-image'>
                                <StaticImage src='../images/sales-64px.png' alt='sales' />
                            </span>
                            <h3>Sales</h3>
                        </div>
                        <p className='group-detail'>
                            <i>
                                <StaticImage className='detail-image' src='../images/telephone.png' alt='telephone' />
                            </i>
                            <span>(+1) (321) 422-8685</span>
                        </p>
                        <p className='group-detail'>
                            <i>
                                <StaticImage className='detail-image' src='../images/send.png' alt='send' />
                            </i>
                            <span>hello@dd.zone</span>
                        </p>
                    </article>
                    <article className='group'>
                        <div className='group-heading'>
                            <span className='group-image'>
                                <StaticImage src='../images/support-64px.png' alt='support' />
                            </span>
                            <h3>Support</h3>
                        </div>
                        <p className='group-detail'>
                            <i>
                                <StaticImage className='detail-image' src='../images/telephone.png' alt='telephone' />
                            </i>
                            <span>(+1) (410) 202-8844</span>
                        </p>
                        <p className='group-detail'>
                            <i>
                                <StaticImage className='detail-image' src='../images/send.png' alt='send' />
                            </i>
                            <span>support@dd.zone</span>
                        </p>
                    </article>
                    <article className='group'>
                        <div className='group-heading'>
                            <span className='group-image'>
                                <StaticImage src='../images/address-64px.png' alt='address' />
                            </span>
                            <h3>Address</h3>
                        </div>
                        <p className='group-detail'>
                            <i>
                                <StaticImage className='detail-image' src='../images/location.png' alt='location' />
                            </i>
                            <span>111 NE 1st St, 8th Floor, 88114 Miami, FL 33132, USA</span>
                        </p>
                    </article>
                </div>
            </div>
        </section>
    </Layout>
);

export default ContactUs
